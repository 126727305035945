import { Types } from '@cornerstonejs/core';
import { Types as ToolsTypes } from '@cornerstonejs/tools';

export default function fillAnnotationsMissingCameraData(
  viewport: Types.IStackViewport | Types.IVolumeViewport,
  annotations: ToolsTypes.Annotations
): void {
  // Using internal field to check if the viewport is in a valid state
  if (!viewport.stackInvalidated) {
    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;
    annotations.forEach(annotation => {
      const { metadata: metaData } = annotation;

      // G3D-520 : Supposing that missing viewPlaneNormal and viewUp
      // in the annotation metadata can be initialized
      // with the initial camera values for the annotated image
      if (
        metaData &&
        metaData.FrameOfReferenceUID === viewport.getFrameOfReferenceUID() &&
        !metaData.viewPlaneNormal?.length &&
        !metaData.viewUp?.length
      ) {
        metaData.viewPlaneNormal = viewPlaneNormal;
        metaData.viewUp = viewUp;
      }
    });
  }
}
